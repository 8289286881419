<template>
  <div class="zone">
    <TJDetailTitle :titleData="'销量统计_省份'" />
    <functionBox 
      :totalText="'总计人份数：'+ $tool.moneyFormatter(Number(sumPeople), 0) +'人份 总计金额：'+ $tool.moneyFormatter(Number(sumRMB), 2) +'元'"
      :titleData="{permission_flag:'Sales',nowID:1}"
      :searhLoading="loading"
      @searchBtn="searchBtn"
      >
      <template>
        <li>
            <el-select
              v-model="province_id"
              placeholder="请选择省份"
              size="small"
              filterable
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
        </li>
        <li>
          <timePicker @setTime="setTime"></timePicker>
        </li>
      </template>
    </functionBox>      
    <!-- <div class="indent">
      <div class="indentone">
        <div class="left cursor">省份</div>
        <div class="line"></div>
        <div class="spital cursor" @click="toAgency">经销商</div>
        <div class="line"></div>
        <div class="Volume cursor" @click="toVolume">报告</div>
        <div class="line"></div>
        <div class="Device cursor" @click="toTurnover">日成交量</div>
        <p class="NumCla">总计人份数： {{ $tool.moneyFormatter(Number(sumPeople), 0) }}人份 总计金额： {{ $tool.moneyFormatter(Number(sumRMB), 2) }}元</p>
      </div>
      <div class="below">
        <div class="city">
          <el-select
            v-model="province_id"
            placeholder="请选择省份"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <timePicker @setTime="setTime"></timePicker>
        <div class="seachone">
          <el-button type="primary" size="small" @click="searchBtn">
            搜索
          </el-button>
        </div>
      </div>
    </div> -->

    <div class="sheet">
      <el-table
        :data="tableData"
        stripe
        height="700"
        v-loading="loading"
        :summary-method="getSummaries"
      >
        <el-table-column
          type="index"
          label="序号"
          height="30"
          align="center"
          width="100"
        >
          <template scope="scope">
            <!-- <span>{{scope.$index }}</span> -->
            <span>{{ serialtext(scope.$index)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="province_name"
          label="省份"
          height="30"
          align="center"
        ></el-table-column>
        <el-table-column label="金额" align="center">
          <template slot-scope="scope">
            {{ $tool.moneyFormatter(Number(scope.row.orders_sum), 2) }}
          </template>
        </el-table-column>
        <el-table-column label="人份数" align="center">
          <template slot-scope="scope">
            {{ $tool.moneyFormatter(Number(scope.row.amount), 0) }}
          </template>
        </el-table-column>

        <el-table-column label="操作 " width="120" align="center">
          <template slot-scope="scope">
            <div class="caozuo">
              <el-popover
                ref="popover0"
                placement="top-start"
                width="20"
                trigger="hover"
                content="构成分析"
              >
                <div
                  slot="reference"
                  class="box"
                  @click="toComposition(scope.row, $event)"
                >
                  <img
                    src="@/assets/img/Composition.png"
                    alt=""
                    @mouseenter="hoverC('lanse1', $event)"
                    @mouseleave="hoverC('Composition', $event)"
                  />
                </div>
              </el-popover>
              |
              <el-popover
                ref="popover1"
                placement="top-start"
                width="30"
                trigger="hover"
                content="时序分析"
              >
                <div
                  slot="reference"
                  class="box"
                  @click="toseries(scope.row, $event)"
                >
                  <img
                    src="@/assets/img/series.png"
                    alt=""
                    @mouseenter="hoverC('lanse2', $event)"
                    @mouseleave="hoverC('series', $event)"
                  />
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import timePicker from '@/components/timePicker'
import functionBox from './components/functionBox.vue'
import { getsale_nationwide } from '@/router/menuMain.js'

export default {
  components: { TJDetailTitle,timePicker,functionBox },
  name: 'Sales',
  data() {
    return {
      options: JSON.parse(localStorage.getItem('province')), //地区列表
      province_id: '', //地区选择值

      radio: '年', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      dateMonthValue: '', //月份和日期选择
      page: 1,
      size: 10,
      total: 0,

      loading: false, //加载动画
      tableData: [],
      nationData: {},//全国数据
      sumPeople: 0,//总人份数
      sumRMB: 0,//总金额
      sale_nationwide: false,
    }
  },
  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },
    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
    format() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy/MM',
        日: 'yyyy/MM/dd',
      }
      return arr[this.radio]
    },
    formatValue() {
      const arr = {
        年: 'yyyy',
        月: 'yyyyMM',
        日: 'yyyyMMdd',
      }
      return arr[this.radio]
    },
    serialtext() {
      return (index)=>{
        if(this.sale_nationwide){
          if(index == 0) {
            return 0
          }else{
            return (this.page-1)*10+index
          }
        }else{
          return (this.page-1)*10+index+1
        }
      }
    },
  },
  watch: {
    // type() {
    //   this.start_stats_year = ''
    //   this.end_stats_year = ''
    //   this.dateMonthValue = ''
    // },
  },
  created() {
    this.sale_nationwide = getsale_nationwide();
  },
  mounted() {
    // 延迟来让时间组件加载出来
    setTimeout(() => {
      this.daily_trade_entireFun();
    }, 200);
  },
  methods: {
    // 根据回调修改当前页的相关属性
    setTime(val) {
      this[val.name] = val.value
    },
    // 全国展示
    daily_trade_entireFun() {
      this.loading = true

      if(this.sale_nationwide){
        let param = {
        ...this.filterQuery(),
        }
        api.daily_trade_entire(param).then(res => {
          if(res.code == 200){
            this.nationData = {
              amount: res.data.amount_sum,
              orders_sum:  res.data.orders_sum,
              province: "",
              province_name: "全国"
            }
            //确认全国展示出现之后，再去请求其他数据
          }
            this.districtDailyTradeSumFun();
        }) .catch((err) => {
          this.districtDailyTradeSumFun();
        })
      }else {
        this.districtDailyTradeSumFun();
      }
    },
    // 省份销量统计省份所占百分比
    districtDailyTradeSumFun() {
      this.loading = true
      let param = {
        province_id: this.province_id, //地区ID

        page: this.page,
        size: this.size,
        ...this.filterQuery(),
      }
      api
        .districtDailyTradeSum(param)
        .then((res) => {
          if (res.code == 200) {
            let data = res.data.data
            this.tableData = data;
            if(this.nationData.province_name){
              this.tableData.unshift(this.nationData);
            }
            this.total = res.data.count
            this.loading = false
            this.sumRMB = res.data.orders_cnt;
					  this.sumPeople = res.data.amount_cnt;
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },

    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },

    

    // 搜索按钮
    searchBtn() {
      this.page = 1
      // this.districtDailyTradeSumFun()
      //  改变搜索条件后，先获取全国，再自动获取其他数据。
      this.daily_trade_entireFun();
    },

    // 翻页
    handleCurrentChange(val) {
      this.page = val
      this.districtDailyTradeSumFun()
    },

    // 鼠标放在操作图标时
    hoverC(a, e) {
      e.target.src = require('@/assets/img/' + a + '.png')
    },

    // 跳转页面携带参数处理
    filterQuery() {
      let param = {
        date_type: '', //日期类型:0:年,1,月,2,日
      }
      if (this.radio == '年') {
        param.start_stats_year = this.start_stats_year
        param.end_stats_year = this.end_stats_year
        param.date_type = 0
      } else if (this.radio == '月') {
        param.start_stats_month =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_month =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
        param.date_type = 1
      } else if (this.radio == '日') {
        param.start_stats_day =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_day =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
        param.date_type = 2
      }
      return param
    },
    
    // 跳转-构成分析-页面
    toComposition(item, e) {
      this.hoverC('Composition', e)
      let query = {
        province_name: item.province_name,
        province_id: item.province,
        ...this.filterQuery(),
        date_type: this.radio,
      }
      this.$router.push({ path: '/GoComposition', query })
    },
    // 跳转-时序分析--页面
    toseries(item, e) {
      this.hoverC('series', e)
      let query = {
        province_name: item.province_name,
        province_id: item.province,
        ...this.filterQuery(),
        date_type: this.radio,
      }
      this.$router.push({ path: '/GoTime', query })
    },

    toVolume() {
      this.$router.push({ path: '/Volume' })
    },
    toAgency() {
      this.$router.push({ path: '/Agency' })
    },
    toTurnover() {
      this.$router.push({ path: '/Turnover' })
    },

    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' '
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
  },
}
</script>

<style lang="scss" scoped>
.el-table {
  .caozuo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    color: #d0d7e0;
    cursor: pointer;
    .box {
      width: 36px;
      height: 36px;
    }
    img {
      width: 36px;
      height: 36px;
    }
  }
}
.indent {
  height: px(121);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
  .indentone {
    width: 100%;
    height: px(56);
    display: flex;
    border-bottom: 1px solid #d0d7e0;
    margin-bottom: px(15);
    .NumCla{
      justify-content: end;
      width: 70%;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
    .cursor {
      cursor: pointer;
    }
    .left {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
    .spital {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }
    .Volume {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }
    .Device {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #666666ff;
      margin-right: px(20);
    }

    .line {
      width: 1px;
      height: 13px;
      background-color: rgba(208, 215, 224, 1);
      margin-top: px(16);
      margin-right: px(20);
    }
  }

  .below {
    //height: px(36);
    display: flex;
    .calendar {
      margin-left: px(10);
    }
    .cityProvince {
      margin-left: px(10);
    }
    .calendarone {
      display: flex;
      margin-left: px(10);
    }
    .seachone {
      margin-left: px(10);
    }
  }
}

.sheet {
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);
  ::v-deep .el-table__footer-wrapper .cell {
    font-size: px(14);
    font-weight: bold;
    color: #006ed0ff;
  }
}
</style>
